const CryptoJS = require('crypto-js')

export function encryptWithAES(text) {
  const passphrase = process.env.VUE_APP_AES
  return CryptoJS.AES.encrypt(text, passphrase).toString()
}

export function decryptWithAES(ciphertext) {
  const passphrase = process.env.VUE_APP_AES
  const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase)
  const originalText = bytes.toString(CryptoJS.enc.Utf8)
  return originalText
}
