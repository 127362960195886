<template>
  <v-app>
    <component :user="user" :is="layout">
      <router-view :user="user" :layout.sync="layout" />
    </component>
  </v-app>
</template>

<script>
import * as fb from "@/firebase";
import "@/app.css";

export default {
  name: "App",

  components: {},

  data: () => ({
    layout: `div`,
    user: {},
    authUser: {},
    isAdmin: false,
    isSubscribed: false,
    isRelative: false,
    isCaregiver: false,
    isHealthAdmin: false,
    isOwner: false,
    claims: {},
  }),
  watch: {
    authUser: {
      immediate: true,
      handler() {
        if (this.authUser) {
          console.log(this.authUser);
          const isAnOwner = this.authUser.roles?.includes("owner");
          fb.auth.currentUser.getIdTokenResult().then((claims) => {
            let userClaims = claims.claims;

            if (userClaims.admin) {
              this.isAdmin = userClaims.admin;
            } else {
              this.isAdmin = false;
            }

            if (userClaims.relative) {
              this.isRelative = userClaims.relative;
            } else {
              this.isRelative = false;
            }

            if (userClaims.caregiver) {
              this.isCaregiver = userClaims.caregiver;
            } else {
              this.isCaregiver = false;
            }

            if (userClaims.healthAdmin) {
              this.isHealthAdmin = userClaims.healthAdmin;
            } else {
              this.isHealthAdmin = false;
            }

            if (userClaims.subscribed) {
              this.isSubscribed = userClaims.subscribed;
            } else {
              this.isSubscribed = false;
            }

            // console.log('userClaims', userClaims)
          });

          this.user = {
            isAdmin: this.isAdmin,
            isSubscribed: this.isSubscribed,
            isRelative: this.isRelative,
            isCaregiver: this.isCaregiver,
            isHealthAdmin: this.isHealthAdmin,
            isOwner: isAnOwner,
            id: this.authUser.id,
            ...this.authUser,
          };
          // console.log(this.user)
        }
      },
    },
  },
  async created() {
    if (fb.auth.currentUser) {
      await this.$bind("authUser", fb.usersCollection.doc(fb.auth.currentUser.uid));
    }
  },
};
</script>
