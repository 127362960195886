import { auth, db } from '@/firebase';
import { logContactUsLead, logLeadEntry, logWaitlistLead } from '@/googleEvent.js';
import { decryptWithAES } from '@/protect.js';
import firebase from 'firebase/app';
import moment from 'moment';
import storageHelper from 'storage-helper';
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

var userClaims = {};
var userData = {};

const getUserDoc = async (uid) => {
  const userDoc = await db.collection('users').doc(uid).get();
  const isOwner = userDoc.data().roles.includes('owner');
  return { ...userDoc.data(), isOwner };
};

const routes = [
  {
    path: '/',
    name: 'Homepage',
    component: () => import('@/views/public/Home.vue'),
    beforeEnter(to, from, next) {
      document.title = `${process.env.VUE_APP_TITLE} | Caregiver Support Tool  |  Aging in Place System`;
      const _desc = `Care Bloom is a start up investment opportunity in the fast growing Age in Place field (216) 342-3043.`;
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', _desc);
      next();
    },
  },
  {
    path: '/caregivers',
    name: 'Caregiver',
    component: () => import('@/views/public/CaregiverLanding.vue'),
    beforeEnter(to, from, next) {
      document.title = `${process.env.VUE_APP_TITLE} | Caregiver`;
      const _desc = `Care Bloom is a start up investment opportunity in the fast growing Age in Place field (216) 342-3043.`;
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', _desc);
      next();
    },
  },
  {
    path: '/partners',
    name: 'Partners',
    component: () => import('@/views/public/PartnersLanding.vue'),
    beforeEnter(to, from, next) {
      document.title = `${process.env.VUE_APP_TITLE} | Partners`;
      const _desc = `Care Bloom is a start up investment opportunity in the fast growing Age in Place field (216) 342-3043.`;
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', _desc);
      next();
    },
  },
  {
    path: '/event',
    name: 'Event',
    component: () => import('@/views/public/Home.vue'),
    beforeEnter(to, from, next) {
      document.title = `${process.env.VUE_APP_TITLE} | Partners`;
      const _desc = `Care Bloom is a start up investment opportunity in the fast growing Age in Place field (216) 342-3043.`;
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', _desc);
      //set event tag "cb_event_lead" to true in local storage
      storageHelper.setItem('cb_lead', 'event_lead');
      storageHelper.setItem('cb_contact_lead', 'event_lead');
      logLeadEntry('event_lead');
      next('/');
    },
  },
  {
    path: '/how-it-works',
    name: 'HowItWorks',
    component: () => import('@/views/public/HowItWorks.vue'),
    beforeEnter(to, from, next) {
      document.title = `Wearable Medical Device | Patient Monitoring | Home Health Care Tracking`;
      const _desc = `Care Bloom is taking startup investors now for this innovative Wearable Medical Device in Home Health Care.(216) 342-3043.`;
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', _desc);
      next();
    },
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: () => import('@/views/public/Pricing.vue'),
    beforeEnter(to, from, next) {
      document.title = `Tracking Operating Alert Device | Contact Tracing | Behavior Tracking`;
      const _desc = `Care Bloom is a great start up investment opportunity in the fast growing Age in Place field (216) 342-3043.`;
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', _desc);
      next();
    },
  },
  {
    path: '/demo',
    name: 'Demo',
    component: () => import('@/views/public/Demo.vue'),
    beforeEnter(to, from, next) {
      document.title = `Tracking Awareness Device | Fall Alert | Health Alert System`;
      const _desc = `Care Bloom is a great start up investment opportunity in the fast growing Age in Place field (216) 342-3043.`;
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', _desc);
      next();
    },
  },
  {
    path: '/lilypad-schematic',
    name: 'LilypadSchematic',
    component: () => import('@/views/public/LilypadSchematic.vue'),
    beforeEnter(to, from, next) {
      document.title = `Wearable Medical Device | Patient Monitoring | Home Health Care Tracking`;
      const _desc = `Care Bloom is taking startup investors now for this innovative Wearable Medical Device in Home Health Care.(216) 342-3043.`;
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', _desc);
      next();
    },
  },
  {
    path: '/functionality-specs',
    name: 'FunctionalitySpecs',
    component: () => import('@/views/public/FunctionalitySpecs.vue'),
    beforeEnter(to, from, next) {
      document.title = `Wearable Medical Device | Patient Monitoring | Home Health Care Tracking`;
      const _desc = `Care Bloom is taking startup investors now for this innovative Wearable Medical Device in Home Health Care.(216) 342-3043.`;
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', _desc);
      next();
    },
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import('@/views/public/PrivacyPolicy.vue'),
    beforeEnter(to, from, next) {
      document.title = `Privacy Policy | Caregiver Support Tool  |  Aging in Place System`;
      const _desc = `Care Bloom is taking startup investors now for this innovative Wearable Medical Device in Home Health Care.(216) 342-3043.`;
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', _desc);
      next();
    },
  },
  {
    path: '/why',
    name: 'Why',
    component: () => import('@/views/public/Why.vue'),
    beforeEnter(to, from, next) {
      document.title = `Tracking Awareness Device | Fall Detection Alerts | Emergency Contact Communication`;
      const _desc = `Care Bloom is taking startup investors now for this innovative Wearable Medical Device. (216) 342-3043`;
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', _desc);
      next();
    },
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/public/About.vue'),
    beforeEnter(to, from, next) {
      document.title = `Medical Tracking Help | Health Alert Systems | Home Health Care`;
      const _desc = `Care Bloom is a great place for startup investors to add their seed funding. (216) 342-3043.`;
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', _desc);
      next();
    },
  },
  {
    path: '/investors',
    name: 'Investors',
    component: () => import('@/views/public/Investors.vue'),
    beforeEnter(to, from, next) {
      document.title = `Care Bloom Pitch Deck | Caregiver Support Tool  |  Aging in Place System`;
      const _desc = `Care Bloom is a start up investment opportunity in the fast growing Age in Place field (216) 342-3043.`;
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', _desc);
      next();
    },
  },
  {
    path: '/buy',
    name: 'Buy',
    component: () => import('@/views/public/Buy.vue'),
    beforeEnter(to, from, next) {
      document.title = `Tracking Operating Alert Device | Contact Tracing | Behavior Tracking`;
      const _desc = `Care Bloom is a great start up investment opportunity in the fast growing Age in Place field (216) 342-3043.`;
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', _desc);
      next();
    },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/views/public/Contact.vue'),
    beforeEnter(to, from, next) {
      document.title = `Care Bloom | Caregiver Support Tool  |  Aging in Place System`;
      const _desc = `Care Bloom is a start up investment opportunity in the fast growing Age in Place field (216) 342-3043.`;
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', _desc);
      //check local storage for event tag "cb_lead" and log lead entry
      if (storageHelper.getItem('cb_contact_lead')) {
        logContactUsLead(storageHelper.getItem('cb_contact_lead'));
        storageHelper.removeItem('cb_contact_lead');
      }
      next();
    },
  },
  {
    path: '/waitlist',
    name: 'Waitlist',
    component: () => import('@/views/public/Waitlist.vue'),
    beforeEnter(to, from, next) {
      document.title = `Care Bloom Waitlist | Caregiver Support Tool  |  Aging in Place System`;
      const _desc = `Care Bloom is a start up investment opportunity in the fast growing Age in Place field (216) 342-3043.`;
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', _desc);


      //check local storage for event tag "cb_lead" and log lead entry
      if (storageHelper.getItem('cb_lead')) {
        logWaitlistLead(storageHelper.getItem('cb_lead'));
        storageHelper.removeItem('cb_lead');
      }
      next();
    },
  },
  {
    path: '/case-study',
    name: 'CaseStudy',
    component: () => import('@/views/public/CaseStudy.vue'),
    beforeEnter(to, from, next) {
      document.title = `Tracking Operating Alert Device | Contact Tracing | Behavior Tracking`;
      const _desc = `Care Bloom is a great start up investment opportunity in the fast growing Age in Place field (216) 342-3043.`;
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', _desc);
      next();
    },
  },
  {
    path: '/pitch-deck',
    name: 'PitchDeck',
    component: () => import('@/views/public/PitchDeck.vue'),
    beforeEnter(to, from, next) {
      document.title = `Tracking Operating Alert Device | Contact Tracing | Behavior Tracking`;
      const _desc = `Care Bloom is a great start up investment opportunity in the fast growing Age in Place field (216) 342-3043.`;
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', _desc);
      if (!storageHelper.getItem('cb-secure')) {
        next('/protected-pdf');
      } else if (
        moment(JSON.parse(storageHelper.getItem('cb-secure')).expiry) < moment()
      ) {
        storageHelper.removeItem('cb-secure');
        next('/protected-pdf');
      } else if (
        decryptWithAES(JSON.parse(storageHelper.getItem('cb-secure')).code) !==
        decryptWithAES(process.env.VUE_APP_PDPW)
      ) {
        next('/protected-pdf');
      } else if (
        decryptWithAES(JSON.parse(storageHelper.getItem('cb-secure')).code) ===
        decryptWithAES(process.env.VUE_APP_PDPW)
      ) {
        next();
      }
    },
  },
  {
    path: '/success',
    name: 'success',
    component: () => import('@/views/public/Success.vue'),
    beforeEnter: async (to, from, next) => {
      if (!auth.currentUser) {
        next('/login');
      } else {
        document.title = `Success | ${process.env.VUE_APP_TITLE}`;
        const _desc = `Success | ${process.env.VUE_APP_TITLE}`;
        document
          .querySelector('meta[name="description"]')
          .setAttribute('content', _desc);

        next();
      }
    },
  },
  {
    path: '/canceled',
    name: 'canceled',
    component: () => import('@/views/public/Canceled.vue'),
    beforeEnter: async (to, from, next) => {
      if (!auth.currentUser) {
        next('/login');
      } else {
        document.title = `Canceled | ${process.env.VUE_APP_TITLE}`;
        const _desc = `Canceled | ${process.env.VUE_APP_TITLE}`;
        document
          .querySelector('meta[name="description"]')
          .setAttribute('content', _desc);

        next();
      }
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/Dashboard.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then(async (user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
          userData = await getUserDoc(user.uid);
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (userClaims.admin || userClaims.healthAdmin) {
        next();
      } else if (userData.isOwner) {
        next('/patients');

      } else {
        next('/access-denied');
      }
    },
  },
  {
    path: '/manage/locations',
    name: 'ManageLocations',
    component: () => import('@/views/ManageLocations.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then(async (user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (userClaims.admin) {
        next();
      } else {
        next('/access-denied');
      }
    },
  },
  {
    path: '/patients',
    name: 'Patients',
    component: () => import('@/views/Patients.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then(async (user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
          userData = await getUserDoc(user.uid);
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (userClaims.admin || userData.isOwner) {
        next();
      } else {
        next('/access-denied');
      }
    },
  },
  {
    path: '/companies',
    name: 'Companies',
    component: () => import('@/views/HealthCareCompanies.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then(async (user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (userClaims.admin || userClaims.healthAdmin) {
        next();
      } else {
        next('/access-denied');
      }
    },
  },
  {
    path: '/caregivers-relatives',
    name: 'CaregiversRelatives',
    component: () => import('@/views/CaregiversRelatives.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then(async (user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (userClaims.admin || userClaims.healthAdmin) {
        next();
      } else {
        next('/access-denied');
      }
    },
  },
  {
    path: '/admins',
    name: 'Admins',
    component: () => import('@/views/Admins.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then(async (user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (userClaims.admin) {
        next();
      } else {
        next('/access-denied');
      }
    },
  },
  {
    path: '/users-permissions',
    name: 'UsersPermissions',
    component: () => import('@/views/UsersPermissions.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then(async (user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (userClaims.admin) {
        next();
      } else {
        next('/access-denied');
      }
    },
  },
  {
    path: '/import-equipment',
    name: 'EquipmentImportView',
    component: () => import('@/views/EquipmentImportView.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then(async (user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (userClaims.admin) {
        next();
      } else {
        next('/access-denied');
      }
    },
  },
  {
    path: '/import-patients',
    name: 'PatientImportView',
    component: () => import('@/views/PatientImportView.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then(async (user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (userClaims.admin || userClaims.healthAdmin) {
        next();
      } else {
        next('/access-denied');
      }
    },
  },
  {
    path: '/myaccount',
    name: 'MyAccount',
    component: () => import('@/views/MyAccount.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then(async (user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
          userData = await getUserDoc(user.uid);
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (userClaims.admin || userClaims.healthAdmin || userData.isOwner) {
        next();
      } else {
        next('/access-denied');
      }
    },
  },
  {
    path: '/statistics',
    name: 'Stats',
    component: () => import('@/views/Stats.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then(async (user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (userClaims.admin) {
        next();
      } else {
        next('/access-denied');
      }
    },
  },
  {
    path: '/testing',
    name: 'Testing',
    component: () => import('@/views/Testing/Testing.vue'),
    meta: {
      requiresPw: true,
    },
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then(async (user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (userClaims.admin || userClaims.healthAdmin) {
        next();
      } else {
        next('/access-denied');
      }
    },
  },
  {
    path: '/ota',
    name: 'OTA',
    component: () => import('@/views/Testing/OTAUpload.vue'),
    meta: {
      requiresPw: true,
    },
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then(async (user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (userClaims.admin) {
        next();
      } else {
        next('/access-denied');
      }
    },
  },
  {
    path: '/ota/anchor',
    name: 'OTA-anchor',
    component: () => import('@/views/Testing/OTAUploadAnchors.vue'),
    meta: {
      requiresPw: true,
    },
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then(async (user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (userClaims.admin) {
        next();
      } else {
        next('/access-denied');
      }
    },
  },
  {
    path: '/migrate',
    name: 'Migrate',
    component: () => import('@/views/Testing/Migrator.vue'),
    meta: {
      requiresPw: true,
    },
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then(async (user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (userClaims.admin) {
        next();
      } else {
        next('/access-denied');
      }
    },
  },
  {
    path: '/ota-test',
    name: 'OTATest',
    component: () => import('@/views/Testing/OTAUploadTest.vue'),
    meta: {
      requiresPw: true,
    },
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then(async (user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (userClaims.admin) {
        next();
      } else {
        next('/access-denied');
      }
    },
  },
  {
    path: '/ota-test/anchor',
    name: 'OTATest',
    component: () => import('@/views/Testing/OTAUploadAnchorsTest.vue'),
    meta: {
      requiresPw: true,
    },
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then(async (user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (userClaims.admin) {
        next();
      } else {
        next('/access-denied');
      }
    },
  },
  // {
  //   path: '/faker',
  //   name: 'Faker',
  //   component: () => import('@/views/Testing/Faker.vue'),
  //   meta: {
  //     requiresPw: true,
  //   },
  //   beforeEnter: async (to, from, next) => {
  //     await firebase.getCurrentUser().then(async(user) => {
  //       if (user) {
  //         user.getIdTokenResult().then((claims) => {
  //           userClaims = claims.claims
  //         })
  //       }
  //     })

  //     if (!auth.currentUser) {
  //       next('/login')
  //     } else if (userClaims.admin) {
  //       next()
  //     } else {
  //       next('/access-denied')
  //     }
  //   },
  // },
  {
    path: '/logs',
    name: 'Logs',
    component: () => import('@/views/Testing/Logs.vue'),
    meta: {
      requiresPw: true,
    },
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then(async (user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (userClaims.admin) {
        next();
      } else {
        next('/access-denied');
      }
    },
  },

  {
    path: '/patient/:locationLink/:patientLink',
    name: 'Patient',
    props: true,
    component: () => import('@/views/PatientDetail.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then(async (user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
          userData = await getUserDoc(user.uid);
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (userClaims.admin || userClaims.healthAdmin || userData.isOwner) {
        next();
      } else {
        next('/access-denied');
      }
    },
  },
  {
    path: '/caregiver/:caregiverLink',
    name: 'CaregiverDetail',
    props: true,
    component: () => import('@/views/CaregiverDetail.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then(async (user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (userClaims.admin || userClaims.healthAdmin) {
        next();
      } else {
        next('/access-denied');
      }
    },
  },
  {
    path: '/location/:locationLink',
    name: 'Location',
    props: true,
    component: () => import('@/views/LocationDetail.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then(async (user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (userClaims.admin || userClaims.healthAdmin) {
        next();
      } else {
        next('/access-denied');
      }
    },
  },
  {
    path: '/dashboard/location/:locationLink',
    name: 'LocationDashboard',
    props: true,
    component: () => import('@/views/LocationDashboard.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then(async (user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (userClaims.admin || userClaims.healthAdmin) {
        next();
      } else {
        next('/access-denied');
      }
    },
  },
  {
    path: '/dashboard/locations',
    name: 'LocationsDashboard',
    props: true,
    component: () => import('@/views/LocationsDashboard.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then(async (user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (userClaims.admin || userClaims.healthAdmin) {
        next();
      } else {
        next('/access-denied');
      }
    },
  },
  {
    path: '/dashboard/caregivers',
    name: 'CaregiversDashboard',
    props: true,
    component: () => import('@/views/CaregiversDashboard.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then(async (user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (userClaims.admin) {
        next();
      } else {
        next('/access-denied');
      }
    },
  },
  {
    path: '/dashboard/relatives',
    name: 'RelativesDashboard',
    props: true,
    component: () => import('@/views/RelativesDashboard.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then(async (user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (userClaims.admin) {
        next();
      } else {
        next('/access-denied');
      }
    },
  },
  {
    path: '/company/:companyLink',
    name: 'Company',
    props: true,
    component: () => import('@/views/CompanyDetail.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then(async (user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (userClaims.admin || userClaims.healthAdmin) {
        next();
      } else {
        next('/access-denied');
      }
    },
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: () => import('@/views/SignUp.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/download',
    name: 'Download',
    component: () => import('@/views/public/Download.vue'),
  },
  {
    path: '/watch',
    name: 'Watch',
    component: () => import('@/views/public/Watch.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/Register.vue'),
  },
  {
    path: '/setpassword',
    name: 'SetPassword',
    component: () => import('@/views/SetPassword.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then(async (user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else {
        next();
      }
    },
  },
  {
    path: '/thankyou',
    name: 'ThankYou',
    component: () => import('@/views/ThankYou.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then(async (user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else {
        next();
      }
    },
  },
  {
    path: '/access-denied',
    name: 'AccessDenied',
    component: () => import('@/views/AccessDenied.vue'),
  },

  {
    path: '/protected',
    name: 'Protected',
    component: () => import('@/views/Protected.vue'),
    beforeEnter: async (to, from, next) => {
      if (process.env.VUE_APP_ISPW !== 'T') {
        next('/');
      } else {
        next();
      }
    },
  },
  {
    path: '/protected-pdf',
    name: 'ProtectedPdf',
    component: () => import('@/views/public/ProtectedPdf.vue'),
    beforeEnter: async (to, from, next) => {
      next();
    },
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/NotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
};

router.beforeEach((to, from, next) => {
  if (to.meta.requiresPw && process.env.VUE_APP_ISPW == 'T') {
    if (!storageHelper.getItem('cb-user-password')) {
      next('/protected');
    } else if (
      storageHelper.getItem('cb-user-password') !== process.env.VUE_APP_PW
    ) {
      next('/protected');
    } else next();
  } else next();
});

export default router;
